import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import Image from "gatsby-image";
import {getProperty} from "../../utils/helperFn";
import YouFibreIcons from "../../components/YouFibreIcons/YouFibreIcons";

const SectionStyled = styled(Section)`
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const FeatureCard = ({
     color = "primary",
     iconName,
     title,
     type,
     children,
     ...rest
   }) => (
    <Box width={"100%"} bg="#f7f7fb" p="0" borderRadius={10} {...rest}>
      <Row>
        <Col sm="12" class="pl-0 pr-0">
          <a href={type == 'chat' ? "javascript:void(zE('messenger', 'open'))" : "tel:08002700000"} className="flex-center support-link">
            <Box
              size={69}
              minWidth={69}
              minHeight={69}
              borderRadius="50%"
              color={color}
              className="d-flex justify-content-center align-items-center"
              mr="20px"
            >
              <img src={YouFibreIcons(iconName)} alt="Feature icon" style={{width: '54px'}} />
            </Box>
            <Text
              color="heading"
              as="h3"
              fontSize={4}
              fontWeight={500}
              letterSpacing={-0.75}
              mb={0}
            >
              {title}
            </Text>
          </a>
        </Col>
        <Col sm="12">
          <Text fontSize={2} lineHeight={1.75}>
            {children}
          </Text>
        </Col>
      </Row>
    </Box>
);

const SupportSectionSetup = (props) => {
  return (
    <>
      <SectionStyled bg="#f7f7fb">
        <Container>
          <Row className="align-items-center">
            {/* <Col lg="7" className="mb-4 mb-lg-0 pl-lg-5 order-lg-2">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Image fluid={props.pageData.section_image.childImageSharp.fluid} alt="YouFibre Customer Support"/>
              </div>
            </Col> */}
            <Col lg="5" md={9} className="order-lg-1">
              <div>
                <Title>{getProperty(props.pageData, 'section_title')}</Title>
                <Text>{getProperty(props.pageData, 'section_text')}</Text>
                <FeatureCard
                    color="primary"
                    iconName={getProperty(props.pageData, 'feature1_icon')}
                    title={getProperty(props.pageData, 'feature1_title')}
                    type="call"
                    mt={4}
                >
                    {getProperty(props.pageData, 'feature1_text')}
                </FeatureCard>
                  <FeatureCard
                      color="primary"
                      iconName={getProperty(props.pageData, 'feature2_icon')}
                      title={getProperty(props.pageData, 'feature2_title')}
                      type="chat"
                      mt={1}
                  >
                      {getProperty(props.pageData, 'feature2_text')}
                  </FeatureCard>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default SupportSectionSetup;
